import { Component, OnInit, ViewChild } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from "@angular/forms";
import { Router } from '@angular/router';
import { environment } from 'src/environments/environment.prod';
import { AuthService } from 'src/app/shared/service/auth.service';

import { HttpService } from 'src/app/shared/service/http.service';
@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {
  
  environment;
  @ViewChild('tabSet', {static: false}) tabSet;
  public loginForm: FormGroup;
  public registerForm: FormGroup;
  public resetPwForm: FormGroup;

  loading: boolean = false;
  lostPassword: boolean = false;

  constructor(
    private fb: FormBuilder,
    private router: Router,
    private auth: AuthService,
    private httpService:HttpService,
    private authService: AuthService,
    private formBuilder: FormBuilder
  ) {
    this.createLoginForm();
    this.createRegisterForm();
    this.recoverPass();
  }

  async login() {
    this.loading = true;
    await this.auth.login(this.loginForm.value)
    this.loading = false;
    // this.auth.signIn(this.loginForm.value).subscribe(
    //   user => {
    //     this.loading = false;
    //     this.router.navigateByUrl('/home');
    //   },
    //   async err => {
    //     this.loading = false;
    //     alert(err.message);
    //   }
    // );
  }
  changeTab(e){
    if (e.nextId !== "recoverTab") {
      this.lostPassword = false;
    }
  }
  async register() {
    this.loading = true;

    this.auth.signUp(this.registerForm.value).then(async res => {
        alert("Foydalanuvchi muvaffaqiyatli qo'shildi!")
        this.router.navigateByUrl('/home');
        this.loading = false;
    }, async err => {
        alert(err.message);
        this.loading = false;
    });
  }
 lostLink(){
   this.lostPassword = true;
   setTimeout(()=>{
     this.tabSet.select('recoverTab');
   },100);
 }
  resetPassword(){
    this.loading = true;
    this.httpService.recoverPass(this.resetPwForm.value.email).then(
      async res => {
        this.lostPassword = false;
        alert("Parolni tiklash uchun Email manzilingizga giperhavola jo‘natildi!");
        this.loading = false;
      },
      async err => {
        alert(err.message);
        this.loading = false;
      }
    );
  }

  createLoginForm() {
    this.loginForm = this.fb.group({
      email: ['user@gmail.com', [Validators.required, Validators.email]],
      password: ['123456', [Validators.required, Validators.minLength(6)]]
    });
  }
  // async testest(){
  //   await this.authService.getUsersFirebase().subscribe(async users=>{
  //     console.log('users', users)
  //     const admin = users.filter(v=>v.role==='ADMIN')
  //     // const password = users.filter(v=>v.password)
  //     console.log('admin', admin)
  //     // console.log('password', password)
  //     // const createUser = this.createUsers(users);
  //     // createUser();
  //   });
  // }
  // createUsers(users:any){
  //   let createUser:any;
  //   let index=0;
  //   return createUser = async()=> {
  //       if(index+1===users.length)return;
  //       const newUser = {
  //         email: users[index].email,
  //         first_name:users[index].first_name,
  //         last_name: users[index].last_name,
  //         password: users[index].password?users[index].password:'123456',
  //         patronymic: users[index].patronymic?users[index].patronymic:'',
  //       }
  //       try{
  //         console.log('user', newUser)
  //         const result = await this.httpService.createUser(newUser);
  //         setTimeout(async ()=>{
  //           console.log('create user', result)
  //           index=index+1;
  //           createUser();
  //         },100)
  //       }catch(e){console.log(e);index=index+1;createUser();}

  //   }
  // }
  createRegisterForm() {
    this.registerForm = this.fb.group({
      email: ['', [Validators.required, Validators.email]],
      password: ['', [Validators.required, Validators.minLength(6)]],
      first_name: ['', Validators.required],
      last_name: ['', Validators.required],
      acceptTerms: [false, Validators.requiredTrue]
    });
    
  }
  recoverPass(){
    this.resetPwForm = this.fb.group({
      email: ['', [Validators.required, Validators.email]]
    });
  }

  ngOnInit() {
    this.environment = environment;
  }
  owlcarousel = [
    {
      title: "Welcome to Multikart",
      desc: "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy.",
    },
    {
      title: "Welcome to Multikart",
      desc: "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy.",
    },
    {
      title: "Welcome to Multikart",
      desc: "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy.",
    }
  ]
  owlcarouselOptions = {
    loop: true,
    items: 1,
    dots: true
  };

  onSubmit() {
    
  }

}
