
export const environment = {
  otm: "demo",
  production: true,
  http_auth: "IGIGDhvM9bmt5Wp6",
  otm_name: "Ta'lim sifatini nazorat qilish bo'limi",
  firebaseConfig: {
      apiKey: "AIzaSyDiAjbnVOPQw62A0IO08TA6l1UX8TS3RAE",
      authDomain: "fir-d57d3.firebaseapp.com",
      projectId: "fir-d57d3",
      storageBucket: "fir-d57d3.appspot.com",
      messagingSenderId: "714622570281",
      appId: "1:714622570281:web:a278803f6258e11205b138",
      measurementId: "G-176KSVQ15G"
  },
  http: {
      socket:{
        path: 'wss://tsnq.uz/socket.io' 
      },
      userRegister:{
        path: 'https://tsnq.uz/api/auth/registration'
      },
      recoverPass:{
        path: 'https://tsnq.uz/api/mailing/send-mail'
      },
      allRoles: {
        path: 'https://tsnq.uz/api/auth/login'
      },
      removeRole: {
        path: 'https://tsnq.uz/api/auth/login'
      },
      setRole: {
        path: 'https://tsnq.uz/api/auth/login'
      },
      removeTeacher: {
        path: 'https://tsnq.uz/api/auth/login'
      },
      allUsers: {
        path: 'https://tsnq.uz/api/users/all'
      },
      updateUser: {
        path: 'https://tsnq.uz/api/auth/login'
      },
      deleteUser: {
        path: 'https://tsnq.uz/api/auth/login'
      },
      login: {
        path: 'https://tsnq.uz/api/auth/login'
      },
      autologin: {
        path: 'https://tsnq.uz/api/auth/autologin'
      },
      get: {
          path: "https://tsnq.uz/db/2023/get_data.php",
          file: "pvorey.txt"
      },
      getFieldsInform: {
          path: "https://tsnq.uz/db/2023/get_fields_inform.php"
      },
      delete_only_inform_file: {
          path: "https://tsnq.uz/db/2023/delete_only_inform_file.php"
      },
      setInc: {
          path: "https://tsnq.uz/db/2023/set_data_inc.php",
          file: "pvorey.txt"
      },
      del: {
          path: "https://tsnq.uz/db/2023/del_data.php",
          file: "pvorey.txt"
      },
      setIncDelUpd: {
          path: "https://tsnq.uz/db/2023/set_inc_del_upd.php",
          file: "pvorey.txt"
      },
      remDocDownGrade: {
          path: "https://tsnq.uz/db/2023/rem_doc_with_down_grade.php",
      },
      googleScholarCited: {
          path: "https://tsnq.uz/db/2023/google_scholar.php",
      },
      googleScholarCitedAll: {
          path: "https://tsnq.uz/db/2023/google_scholar_all.php",
      },
      setNewPvo: {
          path: "https://tsnq.uz/db/2023/set_new_pvo.php",
      },
      removePvo: {
          path: "https://tsnq.uz/db/2023/remove_pvo.php",
      },
      controlIndexStatus: {
          path: "https://tsnq.uz/db/2023/control_index_status.php",
      },
      createAnnounce: {
          path: "https://tsnq.uz/db/2023/create_announce.php",
      },
      getAnnounces: {
          path: "https://tsnq.uz/db/2023/get_announces.php",
      },
      removeAnnounce: {
          path: "https://tsnq.uz/db/2023/remove_announce.php",
      },
      editUserAccaunt: {
          path: "https://tsnq.uz/db/2023/edit_user_account.php",
      },
      createIndex: {
          path: "https://tsnq.uz/db/2023/create_index.php",
      }
  },
  tizim: [
      {
          fakultet: "Kompyuter injiniring",
          kafedralar: [
              "Axborot texnologiyalari",
              "Kompyuter tizimlari",
              "Tabiiy fanlar",
          ]
      },
      {
          fakultet: "Dasturiy injiniringi va raqamli iqtisodiyot",
          kafedralar: [
              "Axborot xavfsizligi",
              "Dasturiy injiniring",
              "O'zbek tili va gumanitar fanlar",
          ]
      },
      {
          fakultet: "Telekomunikatsiya injiniringi va kasb ta'limi",
          kafedralar: [
              "Telekomunikatsiya injiniringi",
              "Axborot ta’lim texnologiyalari",
              "Xorijiy tillar",
          ]
      }
  ],
  grastud: 556,
  fakultet: ["Kompyuter injiniring", "Dasturiy injiniringi va raqamli iqtisodiyot", "Telekomunikatsiya injiniringi va kasb ta'limi"],
  kafedra: [
      "Axborot texnologiyalari",
      "Kompyuter tizimlari",
      "Tabiiy fanlar",
      "Axborot xavfsizligi",
      "Dasturiy injiniring",
      "O'zbek tili va gumanitar fanlar",
      "Telekomunikatsiya injiniringi",
      "Axborot ta’lim texnologiyalari",
      "Xorijiy tillar",
  ]
};